<template>
  <div class="cs-card" v-if="creator">
    <router-link class="cs-card-content" :style="`--cover: url(${creator.cover})`" :to="`/${creator.user}`">
      <div class="cs-card-head">
        <div class="cs-card-head-user">
          <figure class="avatar" :style="`--avatar:url(${creator.picture})`"></figure>
          <!-- <figure class="flag" :style="`--flag:url(${creator.country_flag})`" v-if="creator.country_flag"></figure> -->
        </div>
        <div class="cs-card-head-options">
          <a class="cs-card-head-button"><iconic name="ellipsis-v" /></a>
        </div>
      </div>
      <div class="cs-card-info">
        <div class="cs-card-info-content">
          <p class="name" capitalize>{{ creator.name }}</p>
          <span class="userName">@{{ creator.user }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["creator"],
};
</script>

<style lang="scss" scoped>
/*creator-suggest-card*/
$avarat_size: 72px;
$flag_size: 22px;
.cs-card {
  position: relative;
  min-height: 120px;
  &-content {
    @include BgImage();
    background-color: #000;
    background-image: var(--cover);
    border-radius: $mpadding;
    overflow: hidden;
    color: #fff;
    display: block;
  }
  &-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: -$mpadding * 3;
    &-user {
      position: relative;
      margin: $mpadding * 1.5 $mpadding $mpadding $mpadding;
      .avatar {
        width: $avarat_size;
        height: $avarat_size;
        border-radius: 50%;
        @include BgImage();
        background-image: var(--avatar);
        border: solid 2px;
      }
      .flag {
        position: absolute;
        width: $flag_size;
        height: $flag_size;
        border-radius: 50%;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        overflow: hidden;
        background-image: var(--flag);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: solid 1px;
      }
    }
    &-options {
      margin: $mpadding/2;
    }
    &-button {
      width: $mpadding * 2.5;
      height: $mpadding * 2.5;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
      &:hover {
        background: #eeeeee46;
      }
    }
  }
  &-info {
    &-content {
      background: $primary_gradient;
      padding: $mpadding $mpadding $mpadding $avarat_size + $mpadding * 2;
      .name {
        font-size: 110%;
      }
    }
  }
}
</style>
