<template>
  <div class="feed-post" v-if="suggestions && suggestions.length > 0" :key="$global.fastID()" icon="dots_loading">
    <div class="suggest-block">
      <div class="suggest-block-header">
        <div class="title">
          <p bold>{{ $locale["suggestions-label"] }}</p>
        </div>
        <div class="options">
          <a class="button"><iconic name="sliders-v" /></a>
        </div>
      </div>
      <div class="suggest-block-content">
        <div class="loading-content" v-if="loading">
          <iconic name="gloading" />
          <div dspacer />
        </div>
        <VueSlickCarousel v-else-if="suggestions" v-bind="settings" class="suggest-block-carrousel">
          <div v-for="(creators, index) in suggestions" :key="`due-sg-${index}`">
            <div v-for="(creator, creator_index) in creators" :key="`cr-sg-${creator_index}`">
              <CreatorSuggestCard :creator="creator" />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import CreatorSuggestCard from "./CreatorSuggestCard.vue";
export default {
  components: { CreatorSuggestCard },
  data: function() {
    return {
      loading: true,
      suggestions: null,
      settings: {
        arrows: false,
        dots: false,
        mobileFirst: true,
        infinite: true,
        initialSlide: 0.8,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    dueItems: function(items = []) {
      let nItem = 0;
      let cItems = JSON.parse(JSON.stringify(items));
      let newItems = [];
      items.forEach((item) => {
        if (nItem === 1) {
          newItems.push([cItems[0], cItems[1]]);
          cItems.splice(0, 2);
          nItem = 0;
        } else {
          nItem++;
        }
      });
      this.suggestions = newItems;
    },
    getSuggestions: async function() {
      const _host = `${process.env.VUE_APP_API_HOST}creator/suggestions`;
      const search = this.$api.get(`${_host}`);
      search.then((response) => {
        this.loading = false;
        this.dueItems(response.data.creators);
      });
    },
  },
  beforeMount: async function() {
    this.getSuggestions();
  },
};
</script>

<style lang="scss">
.suggest-block {
  position: relative;
  .loading-content {
    padding: 0 $mpadding;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $mpadding/2 $mpadding/2 0 $mpadding/2;
    .title {
      margin: $mpadding/2;
    }
    .options {
      .button {
        width: $mpadding * 2.5;
        height: $mpadding * 2.5;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        color: $sec_color;
        &:hover {
          background: #eeeeee;
        }
      }
    }
  }
  &-content {
    padding: $mpadding/2 0;
    .cs-card {
      padding: $mpadding/2;
    }
    .slick-list {
      padding-left: $mpadding/2;
    }
  }
  &-carrousel {
    position: relative;
  }
}
</style>
